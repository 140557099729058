import {
    ALL_POOLS,
} from "../actions/actionTypes/pool.action-types";


const initialState = {
    allPools: [],

};

export default function pools(state = initialState, action) {
  switch (action.type) {
      case ALL_POOLS: {
          const allPools  = action.payload;

          return {
              ...state,
              allPools,
          };
      }


      default:
          return state;
  }
};
