import { YIELDFARMING_CONTRACT, EOSIO_CONTRACT } from "../constants";
import { fetchRows } from "../helpers/waxChain.helper";


export const getUserBalance = async ({ account, contract }) => {
    const { rows } = await fetchRows({
        contract: contract,
        scope: account.accountName,
        table: 'accounts',
        limit: 1,
    });

    if (!rows[0] || !rows[0].balance)
        return `0`;

    const [value, currency] = rows[0].balance.split(' ');

    return `${Number(value)} ${currency}`;
};

export const getCustomPool = async () => {
    const { rows } = await fetchRows({
        contract: YIELDFARMING_CONTRACT,
        scope: YIELDFARMING_CONTRACT,
        table: 'custompools',
        limit: 1000,
    });
    return rows
};

export const getUserInfo = async ({ activeUser }) => {
    const { rows } = await fetchRows({
        contract: YIELDFARMING_CONTRACT,
        scope: activeUser.accountName,
        table: 'userinfo',
        limit: 100,
    });

    return rows
};

export const createPool = async ({ activeUser }) => {
    await activeUser.signTransaction({
        actions: [
                    {
                        account: YIELDFARMING_CONTRACT,
                        name: 'createpool',
                        authorization: [{
                            actor: activeUser.accountName,
                            permission: 'active',
                        }],
                        data: {
                            pool_owner: activeUser.accountName,
                        },
                    }
                ]
    }, {
        blocksBehind: 3,
        expireSeconds: 30
    });
};

export const depositReward = async ({ activeUser, rewardContract, depositRewardToken, pool, reward }) => {
    const zero = reward.split(' ')[0].toString().split('.').pop().length
    const nameToken = reward.split(' ')[1]

    await activeUser.signTransaction({
        actions: [
            {
                account: rewardContract,
                name: 'transfer',
                authorization: [{
                    actor: activeUser.accountName,
                    permission: 'active',
                }],
                data: {
                    from: activeUser.accountName,
                    to: YIELDFARMING_CONTRACT,
                    quantity: `${Number(depositRewardToken).toFixed(zero)} ${nameToken}`,
                    memo: `deposit pool:${pool}`
                },
            }
        ]
    }, {
        blocksBehind: 3,
        expireSeconds: 30
    });
};

export const editPool = async ({  activeUser, contractNameReward, rewardToken, contractNameStaking, stakingToken, startTime, endTime, pool, reward, rewardContract, stakingContract, minStakingToken }) => {

    const actions = []
    if (contractNameReward !== rewardContract || rewardToken !== reward){
        actions.push(
            {
                account: contractNameReward,
                name: 'transfer',
                authorization: [{
                    actor: activeUser.accountName,
                    permission: 'active',
                }],
                data: {
                    from: activeUser.accountName,
                    to: YIELDFARMING_CONTRACT,
                    quantity: rewardToken,
                    memo: `set reward token:${pool}`
                },
            }
        )
    }

    if ((contractNameStaking !== stakingContract || stakingContract === 'none')){
        actions.push(
            {
                account: contractNameStaking,
                name: 'transfer',
                authorization: [{
                    actor: activeUser.accountName,
                    permission: 'active',
                }],
                data: {
                    from: activeUser.accountName,
                    to: YIELDFARMING_CONTRACT,
                    quantity: stakingToken,
                    memo: `set staking token:${pool}`
                },
            }
        )
    }

    if (minStakingToken !== ''){
        actions.push(
            {
                account: YIELDFARMING_CONTRACT,
                name: 'setminstake',
                authorization: [{
                    actor: activeUser.accountName,
                    permission: 'active',
                }],
                data: {
                    pool_id: pool,
                    owner: activeUser.accountName,
                    min_stake_amount: minStakingToken,
                },
            }
        )
    }



    if (startTime && endTime){
        actions.push(
            {
                account: YIELDFARMING_CONTRACT,
                name: 'poolsetup',
                authorization: [{
                    actor: activeUser.accountName,
                    permission: 'active',
                }],
                data: {
                    owner: activeUser.accountName,
                    pool_id: pool,
                    start_time: Date.parse(startTime)/1000,
                    end_time: Date.parse(endTime)/1000
                },
            }
        )
    }

    await activeUser.signTransaction({
        actions
    }, {
        blocksBehind: 3,
        expireSeconds: 30
    });
};

export const completePool = async ({ activeUser, pool }) => {
    await activeUser.signTransaction({
        actions: [
            {
                account: YIELDFARMING_CONTRACT,
                name: 'completepool',
                authorization: [{
                    actor: activeUser.accountName,
                    permission: 'active',
                }],
                data: {
                    owner: activeUser.accountName,
                    pool_id: pool
                },
            }
        ]
    }, {
        blocksBehind: 3,
        expireSeconds: 30
    });
};

export const stakePool = async ({ activeUser, pool, staked, stakingContract, totalStaked }) => {

    const zero = totalStaked.split(' ')[0].toString().split('.').pop().length
    const nameToken = totalStaked.split(' ')[1]

    await activeUser.signTransaction({
        actions: [
            {
                account: stakingContract,
                name: 'transfer',
                authorization: [{
                    actor: activeUser.accountName,
                    permission: 'active',
                }],
                data: {
                    from: activeUser.accountName,
                    to: YIELDFARMING_CONTRACT,
                    quantity: `${Number(staked).toFixed(zero)} ${nameToken}`,
                    memo: `stake:${pool}`
                },
            }
        ]
    }, {
        blocksBehind: 3,
        expireSeconds: 30
    });
};

export const unstakePool = async ({ activeUser, pool, unstaked, totalStaked }) => {
    const zero = totalStaked.split(' ')[0].toString().split('.').pop().length
    const nameToken = totalStaked.split(' ')[1]


    await activeUser.signTransaction({
        actions: [
            {
                account: YIELDFARMING_CONTRACT,
                name: 'unstake',
                authorization: [{
                    actor: activeUser.accountName,
                    permission: 'active',
                }],
                data: {
                    user: activeUser.accountName,
                    pool_id: pool,
                    quantity: `${Number(unstaked).toFixed(zero)} ${nameToken}`,
                },
            }
        ]
    }, {
        blocksBehind: 3,
        expireSeconds: 30
    });
};

export const collectPool = async ({ activeUser, pool }) => {
    await activeUser.signTransaction({
        actions: [
            {
                account: YIELDFARMING_CONTRACT,
                name: 'collect',
                authorization: [{
                    actor: activeUser.accountName,
                    permission: 'active',
                }],
                data: {
                    user: activeUser.accountName,
                    pool_id: pool,
                },
            }
        ]
    }, {
        blocksBehind: 3,
        expireSeconds: 30
    });
};

export const deletePool = async ({ activeUser, pool }) => {
    await activeUser.signTransaction({
        actions: [
            {
                account: YIELDFARMING_CONTRACT,
                name: 'deletepool',
                authorization: [{
                    actor: activeUser.accountName,
                    permission: 'active',
                }],
                data: {
                    owner: activeUser.accountName,
                    pool_id: pool
                }
            }
        ]
    }, {
        blocksBehind: 3,
        expireSeconds: 30
    });
};

export const buyUserRam = async ({ activeUser, ram }) => {
    await activeUser.signTransaction({
        actions: [
            {
                account: EOSIO_CONTRACT,
                name: 'transfer',
                authorization: [{
                    actor: activeUser.accountName,
                    permission: 'active',
                }],
                data: {
                    from: activeUser.accountName,
                    to: YIELDFARMING_CONTRACT,
                    quantity: `${Number(ram).toFixed(8)} WAX`,
                    memo: `deposit_ram:${activeUser.accountName}`
                    
                },
            }
        ]
    }, {
        blocksBehind: 3,
        expireSeconds: 30
    });
};

export const minStakingToken = async ({ activeUser, pool, contractName, minStakedToken }) => {
    await activeUser.signTransaction({
        actions: [
            {
                account: contractName,
                name: 'setminstake',
                authorization: [{
                    actor: activeUser.accountName,
                    permission: 'active',
                }],
                data: {
                    pool_id: pool,
                    owner: activeUser.accountName,
                    min_stake_amount: `${minStakedToken} WAX`,
                },
            }
        ]
    }, {
        blocksBehind: 3,
        expireSeconds: 30
    });
};