import React, {Suspense, lazy, useEffect, useContext} from "react";
import {UALContext} from "ual-reactjs-renderer";
import { useDispatch } from "react-redux";


import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import {getCustomPool, getUserInfo} from "./services/wax.service";
import {setPools} from "./redux/actions/actionCreators/pool.action-creators";
import {setUserInfo} from "./redux/actions/actionCreators/user.action-creator";
import {handleError} from "./helpers/error.helper";


const Home = lazy(() => import("./containers/Home/Home"));
const MyPool = lazy(() => import("./containers/MyPool/MyPool"));

export default function App() {
    const { activeUser } = useContext(UALContext);
    const dispatch = useDispatch();



    useEffect(() => {
            getCustomPool()
                .then((data) => {
                    dispatch(setPools(data))
                })
                .catch((error) => handleError({ error }));
    }, [dispatch])

    useEffect( () => {
        if (activeUser) {
            getUserInfo( {activeUser} )
                .then((data) => {
                    dispatch(setUserInfo(data))
                })
                .catch((error) => handleError({ error }));
        }
    }, [activeUser, dispatch])

  return (
      <Router>
          <Suspense fallback={<></>}>
              <Switch>
                  <Route path={'/'} component={Home} exact />
                  <Route path={'/mypool'} component={MyPool} exact />

                  <Redirect to="/" />
              </Switch>
              <ToastContainer
                  position="bottom-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
              />
          </Suspense>
      </Router>
  );
}
